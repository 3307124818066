var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("context-title", {
        attrs: { title: "Menu", "passed-actions": _vm.titleBarActions },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex align-center fill-height" },
                  [
                    _c(
                      "v-chip",
                      { attrs: { color: "#4A90E2", label: "", outlined: "" } },
                      [_vm._v(" EN ")]
                    ),
                    _c("h6", { staticStyle: { "padding-left": "7px" } }, [
                      _vm._v("Add languages"),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "v-container",
        { staticClass: "fill-height fluid" },
        [
          _vm.languages.status === _vm.OperationStatus.InProgress
            ? _vm._l(8, function (i) {
                return _c("v-skeleton-loader", {
                  key: i,
                  staticClass: "mt-2",
                  attrs: { type: "list-item", elevation: "2", width: "100%" },
                })
              })
            : [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-list",
                      _vm._l(_vm.languages.value, function (language) {
                        return _c(
                          "v-list-item",
                          { key: language.code },
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", {
                                  domProps: {
                                    textContent: _vm._s(language.display),
                                  },
                                }),
                                _c("v-list-item-subtitle", {
                                  domProps: {
                                    textContent: _vm._s(language.code),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                    _c(
                      "v-card-actions",
                      [
                        _c(
                          "v-dialog",
                          {
                            attrs: { width: "500" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              color: "#93BD20",
                                              dark: "",
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" Add Language ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.dialog,
                              callback: function ($$v) {
                                _vm.dialog = $$v
                              },
                              expression: "dialog",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-card-title", [_vm._v(" Add Language ")]),
                                _c(
                                  "v-card-text",
                                  [
                                    _vm._v(
                                      " Note that this only adds the language to the HUB languages page for this waypoint, and is part of a larger process for enabling a new translation for a waypoint. Please enter the language short code in the field below. "
                                    ),
                                    _c("v-spacer"),
                                    _c("v-text-field", {
                                      attrs: { label: "Language Short Code" },
                                      model: {
                                        value: _vm.languageToAdd,
                                        callback: function ($$v) {
                                          _vm.languageToAdd = $$v
                                        },
                                        expression: "languageToAdd",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("v-spacer"),
                                _c("v-divider"),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "#93BD20", text: "" },
                                        on: {
                                          click: () => {
                                            _vm.dialog = false
                                            _vm.addLanguage()
                                          },
                                        },
                                      },
                                      [_vm._v(" I accept ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
          _c(
            "v-dialog",
            {
              attrs: { "hide-overlay": "", persistent: "", width: "300" },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _c(
                "v-card",
                { attrs: { color: "primary", dark: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _vm._v(" Please stand by "),
                      _c("v-progress-linear", {
                        staticClass: "mb-0",
                        attrs: { indeterminate: "", color: "white" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }