<template>
  <div>
    <context-title title="Menu" :passed-actions="titleBarActions">
      <template v-slot:title>
        <div class="d-flex align-center fill-height">
          <v-chip color="#4A90E2" label outlined> EN </v-chip>
          <h6 style="padding-left: 7px">Add languages</h6>
        </div>
      </template>
    </context-title>
    <v-container class="fill-height fluid">
      <template v-if="languages.status === OperationStatus.InProgress">
        <v-skeleton-loader
          v-for="i in 8"
          :key="i"
          type="list-item"
          elevation="2"
          width="100%"
          class="mt-2"
        />
      </template>
      <template v-else>
        <v-card>
          <v-list>
            <v-list-item
              v-for="language in languages.value"
              :key="language.code"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="language.display"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="language.code"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="#93BD20" dark v-bind="attrs" v-on="on">
                  Add Language
                </v-btn>
              </template>

              <v-card>
                <v-card-title> Add Language </v-card-title>

                <v-card-text>
                  Note that this only adds the language to the HUB languages
                  page for this waypoint, and is part of a larger process for
                  enabling a new translation for a waypoint. Please enter the
                  language short code in the field below.
                  <v-spacer></v-spacer>
                  <v-text-field
                    label="Language Short Code"
                    v-model="languageToAdd"
                  >
                  </v-text-field>
                </v-card-text>
                <v-spacer></v-spacer>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="#93BD20"
                    text
                    @click="
                      () => {
                        dialog = false;
                        addLanguage();
                      }
                    "
                  >
                    I accept
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </template>
      <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import ContextTitle from "components/context_title";
import LineItem from "components/line_item";
import { authHttp, passthrough } from "@/http";
import { OperationStatus } from "helpers/common";
import moment from "moment";
import _ from "lodash";

export default {
    name: "edit_translations",
    components: {
        ContextTitle,
        LineItem,
    },
    data() {
        return {
            dialog: false,
            languages: {
                status: OperationStatus.Unknown,
                error: null,
                value: [],
            },
            languagesAdd: {
                status: OperationStatus.Unknown,
                error: null,
                value: [],
            },
            languageToAdd: null,
        };
    },
    mounted() {
        this.fetchLanguages(this.waypointId);
    },
    created() {},
    methods: {
        async addLanguage() {
            this.languagesAdd.error = null;
            this.languagesAdd.value = [];
            let waypointId = this.waypointId;

            if (!waypointId) {
                this.languagesAdd.status = OperationStatus.Unknown;
                return;
            }

            this.languagesAdd.status = OperationStatus.InProgress;
            passthrough({
                url: "CursusHub_AutoTranslate",
                method: "GET",
                qs: {
                    storeWaypointID: waypointId,
                    languageCode: this.languageToAdd,
                },
            })
                .then((res) => {
                    this.toast(`${res.data.value}.`, "success");
                    this.languagesAdd.status = OperationStatus.Succeeded;
                    this.fetchLanguages(this.waypointId);
                })
                .catch((e) => {
                    this.languagesAdd.status = OperationStatus.Failed;
                    const errorMessage =
            "Unexpected error occurred adding language, please contact tech support";
                    this.toast(errorMessage, "error");
                    this.languagesAdd.error = errorMessage;
                    console.error(errorMessage, e);
                });
        },
        async fetchLanguages(waypointId) {
            this.languages.error = null;
            this.languages.value = [];

            if (!waypointId) {
                this.languages.status = OperationStatus.Unknown;
                return;
            }

            this.languages.status = OperationStatus.InProgress;
            authHttp
                .get(`/api/languages/waypoint/${this.waypointId}`)
                .then((res) => {
                    let languageNames = new Intl.DisplayNames(["en"], {
                        type: "language",
                    });
                    this.languages.value = res.data.languages.map((el) => {
                        return {
                            code: el,
                            display: languageNames.of(el),
                        };
                    }); // en is a default language, should not be shown in a list
                    this.languages.status = OperationStatus.Succeeded;
                })
                .catch((e) => {
                    this.languages.status = OperationStatus.Failed;
                    const errorMessage =
            "Unexpected error occurred during fetching of languages, please try again or contact tech support";
                    this.toast(errorMessage, "error");
                    this.languages.error = errorMessage;
                    console.error(errorMessage, e);
                });
        },
    },
    computed: {
        loading() {
            return this.languages.status == OperationStatus.InProgress || this.languagesAdd.status == OperationStatus.InProgress
        },
        waypointId() {
            return this.$route.params.store;
        },

        titleBarActions() {
            return [
                // {
                //     type: "add-new",
                //     display: "Refresh Menu Items",
                //     run: () => {
                //         this.refreshMenuItems();
                //     },
                // },
                // {
                //     type: "remove-red",
                //     display: "Auto Translate",
                //     run: () => {
                //         this.autoTranslateVisible = true;
                //     },
                // },
                // {
                //     type: "cancel",
                //     display: "Cancel",
                //     run: () => {
                //         this.cancel();
                //     },
                // },
                // {
                //     type: "proceed-green",
                //     display: "Save",
                //     run: () => {
                //         this.saveTranslatedPhrases();
                //     },
                // },
            ];
        },
        OperationStatus: () => OperationStatus,
    },

    watch: {
        waypointId(val) {
            this.fetchLanguages(val);
        },
    },
};
</script>

<style scoped>
.v-dialog {
  overflow: hidden !important;
}
</style>
